import React from 'react';
import logo from '~/components/Layout/img/logo.png';

const Logo = () => (
  <figure className="icon">
    <img
      src={logo}
      width="82"
      height="64"
      alt="Logo Azienda Speciale Cavriago Servizi"
    />
  </figure>
);

export default Logo;
