import React from 'react';
import logo from '~/components/Layout/img/logo.png';

const LogoFooter = () => (
  <figure className="icon">
    <img
      src={logo}
      width="48"
      height="37"
      alt="Logo Azienda Speciale Cavriago Servizi"
    />
  </figure>
);

export default LogoFooter;
