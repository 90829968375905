import React, { useEffect } from 'react';

const AccessiwayScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    const targetElement =
      document.querySelector('body') || document.querySelector('head');

    script.src = 'https://eu.acsbapp.com/apps/app/dist/js/app.js';
    script.async = true;

    script.onload = () => {
      window.acsbJS.init({
        statementLink: '',
        footerHtml:
          '<a href="https://www.accessiway.com/it/home/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
        hideMobile: false,
        hideTrigger: false,
        language: 'it',
        position: 'left',
        leadColor: '#0069fb',
        triggerColor: '#004fce',
        triggerRadius: '50%',
        triggerPositionX: 'left',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'medium',
          triggerPositionX: 'left',
          triggerPositionY: 'bottom',
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: '50%',
        },
      });
    };

    targetElement.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      targetElement.removeChild(script);
    };
  }, []);

  return <></>;
};

export default AccessiwayScript;
